<div class="card">

	<div class="card-container">
		<app-header *ngIf="isAuthenticated" [isAuthenticated]="isAuthenticated" [header]="header"></app-header>
	</div>
	<div class="card-container">
		<router-outlet></router-outlet>
	</div>

	<div class="card-container">
		<app-footer></app-footer>
	</div>
</div>
