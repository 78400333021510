//core
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClient, HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
// ngx translation
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';

//components
import { AppComponent } from './app.component';
import { AutoLoginComponent } from './components/auto-login/auto-login.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { HomeComponent } from './components/home/home.component';

//services
import { OidcSecurityService } from 'angular-auth-oidc-client';

//modules
import { AuthConfigModule } from './services/auth-config.module';
import { AppRoutingModule } from './app-routing.module';

// catalyst
// import { CatalystComponentsModule } from '@lmig/catalyst-component-library';

// guards
import { AuthorizationGuard } from './services/authorization.guard';
import { DeactivateguardService } from './services/deactivateguard.service';

// ngx-spinner
// import { NgxSpinnerModule } from 'ngx-spinner';

// primeng
import {MenubarModule} from 'primeng/menubar';
import {MenuItem} from 'primeng/api';
import {ImageModule} from 'primeng/image';
import {ToolbarModule} from 'primeng/toolbar';
import {ButtonModule} from 'primeng/button';
import {SplitButtonModule} from 'primeng/splitbutton';
import {MenuModule} from 'primeng/menu';
import {TabMenuModule} from 'primeng/tabmenu';
import {TableModule} from 'primeng/table';
import {TabViewModule} from 'primeng/tabview';
import {RadioButtonModule} from 'primeng/radiobutton';

import { SearchGrossPremiumComponent } from './components/search-adjustment/search-gross-premium/search-gross-premium.component';
import { SearchGrossCLaimComponent } from './components/search-adjustment/search-gross-claim/search-gross-claim.component';
import { SearchCededPremiumComponent } from './components/search-adjustment/search-ceded-premium/search-ceded-premium.component';
import { SearchCededClaimComponent } from './components/search-adjustment/search-ceded-claim/search-ceded-claim.component';
import { SearchMgaCashComponent } from './components/search-adjustment/search-mga-cash/search-mga-cash.component';
import { BulkJournalEntryComponent } from './components/create-adjustment/bulk-journal-entry/bulk-journal-entry.component';
import { BulkGrossPremiumComponent } from './components/create-adjustment/bulk-gross-premium/bulk-gross-premium.component';
import { BulkGrossClaimComponent } from './components/create-adjustment/bulk-gross-claim/bulk-gross-claim.component';
import { BulkCashEntryComponent } from './components/create-adjustment/bulk-cash-entry/bulk-cash-entry.component';
import { BulkCededPremiumComponent } from './components/create-adjustment/bulk-ceded-premium/bulk-ceded-premium.component';
import { BulkCededClaimComponent } from './components/create-adjustment/bulk-ceded-claim/bulk-ceded-claim.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { AboutComponent } from './components/about/about.component';
import { ContactComponent } from './components/contact/contact.component';
import { HomeControllerService } from './services/homeController.service';



export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    AutoLoginComponent,
    FooterComponent,
    HomeComponent,
    SearchGrossPremiumComponent,
    SearchGrossCLaimComponent,
    SearchCededPremiumComponent,
    SearchCededClaimComponent,
    SearchMgaCashComponent,
    BulkJournalEntryComponent,
    BulkGrossPremiumComponent,
    BulkGrossClaimComponent,
    BulkCashEntryComponent,
    BulkCededPremiumComponent,
    BulkCededClaimComponent,
    PageNotFoundComponent,
    AboutComponent,
    ContactComponent
  ],
  imports: [
    
    BrowserModule,
    HttpClientModule,
    HttpClientJsonpModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    AppRoutingModule,
    // ToastModule,
    AuthConfigModule,
    // AccordionModule,
    MenubarModule,
    MenuModule,
    TabMenuModule,
    ImageModule,
    ToolbarModule,
    ButtonModule,
    SplitButtonModule,
    TableModule,
    TabViewModule,
    RadioButtonModule
    // NgxSpinnerModule,
    // CardModule,
    // TabViewModule,
    // FileUploadModule
  ],
  providers: [
    // MessageService,
    AuthorizationGuard,
    DeactivateguardService,
    OidcSecurityService,
    HomeControllerService,
    {provide: LocationStrategy, useClass: HashLocationStrategy}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
