import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-search-gross-premium',
  templateUrl: './search-gross-premium.component.html',
  styleUrls: ['./search-gross-premium.component.scss']
})
export class SearchGrossPremiumComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
