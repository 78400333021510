<div class="card">
    <div class="card-container yellow-container bg-yellow-500 border-round p-2 mr-auto">
        <div class="container d-flex justify-content-around d-align-center pb-2">
            <div class="inline-flex">
                <img src="assets/images/LMI_H_BLUE_RGB.svg" alt="Liberty Mutual Logo" class="bg-left-top logo">
            </div>
            <div class="inline-flex text-4xl font-bold" [ngStyle]="{color:'#0e1531'}">
                Financial Close Process
            </div>
            <div class="inline-flex flex-row-reverse flex-wrap card-container float-right">
                <!-- <span class="p-buttonset">
                    <button pButton type="button" label="Home" icon="pi pi-home" (click)="navHome($event)"
                        class="p-button-lg p-button-raised p-button-rounded"  [ngStyle]="{backgroundColor:'#0e1531'}"></button>
                    <button pButton type="button" label="About" icon="pi pi-building" (click)="navAbout($event)"
                        class="p-button-lg p-button-raised p-button-rounded"  [ngStyle]="{backgroundColor:'#0e1531'}"></button>
                    <button pButton type="button" label="Contact" icon="pi pi-inbox" (click)="navContact($event)"
                        class="p-button-lg p-button-raised p-button-rounded"  [ngStyle]="{backgroundColor:'#0e1531'}"></button>
                </span> -->
                <div><a><i class="pi pi-user icon-btn"> </i><br/><span class="icon-label">User Name</span></a></div>
                <div><i class="pi pi pi-inbox icon-btn"></i><br/><span class="icon-label" [ngClass]="{'active': selectedMenu == 'Contact'}">Contact</span></div>
                <div><i class="pi pi-building icon-btn"></i><br/><span class="icon-label" [ngClass]="{'active': selectedMenu == 'About'}">About</span></div>
                <div><i class="pi pi-home icon-btn"> </i><br/><span class="icon-label" [ngClass]="{'active': selectedMenu == 'Home'}">Home</span></div>

                
            </div>
        </div>
        <div class="container ml-4 mr-4 ui-menubar ui-widget">
            <p-menubar [model]="fcrItems"></p-menubar>
        </div>
    </div>
</div>