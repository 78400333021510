// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  APIEndpoint:'https://localhost:5001/',
  STSServer: 'https://login.microsoftonline.com/08a83339-90e7-49bf-9075-957ccd561bf1/v2.0',
  authWellknownEndpoint: 'https://login.microsoftonline.com/08a83339-90e7-49bf-9075-957ccd561bf1/v2.0',
  clientId: '2e99f25d-7702-4c1e-a6d9-d977f421dddd',
  scope: 'openid profile email 2e99f25d-7702-4c1e-a6d9-d977f421dddd/.default',
  responseType: 'id_token token',
  maxIdTokenIatOffsetAllowedInSeconds: 600,
  AppEnv: 'development',
  AppVersion: '2.0',
  Homepage: 'https://fcr-dat-dev.lmig.com/',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
