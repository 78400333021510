import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AuthorizationGuard {
  constructor(
    private oidcSecurityService: OidcSecurityService, 
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    console.log('this is authgard page');
    return this.oidcSecurityService.isAuthenticated$.pipe(
      map((isAuthenticated: Boolean) => {
        if (!isAuthenticated ) {
          this.router.navigate(['/forbidden']);
          return false;
        }

        return true;
      })
    );
  }

  getAuthorizationHeaderValue(): string {
    if (this.oidcSecurityService != null) {
      return 'Bearer ' + this.oidcSecurityService.getToken();
    } else {
      return '';
    }
  }
}

export interface CustomUserInfo {
  family_name: string;
  given_name: string;
  name: string;
  unique_name: string;
  employeeid: string;
}
