//old
import { APP_INITIALIZER, NgModule } from '@angular/core';
//new
import { HttpClient } from '@angular/common/http';
//import {NgModule } from '@angular/core';

//old
import { AuthModule, LogLevel, OidcConfigService, OidcSecurityService } from 'angular-auth-oidc-client';

//new
//import { AuthModule, LogLevel, OidcSecurityService, OpenIdConfiguration, StsConfigHttpLoader, StsConfigLoader } from 'angular-auth-oidc-client';
// import { Observable } from 'rxjs';
// import { map } from 'rxjs';


import { environment } from '../../environments/environment';

export function loadConfig(oidcConfigService: OidcConfigService) {
  return () =>
    oidcConfigService.withConfig({
      stsServer: environment.STSServer,
      authWellknownEndpoint: environment.authWellknownEndpoint ,
      redirectUrl: environment.Homepage,
      //redirectUrl: "http://localhost:4200/",        
      postLogoutRedirectUri: environment.Homepage, // window.location.origin + '/#/logout',
      clientId: environment.clientId,
      scope: environment.scope,
      responseType: environment.responseType,
      silentRenew: true,
      maxIdTokenIatOffsetAllowedInSeconds: environment.maxIdTokenIatOffsetAllowedInSeconds,
      issValidationOff: true,
      autoUserinfo: false,
      silentRenewUrl: environment.Homepage , //window.location.origin + '/silent-refresh.html',
      logLevel: LogLevel.Error,
      
      
      // customParams: {
      //   prompt: 'login', // login, consent
      // },
    });
}
//old
// export function loadConfig(oidcConfigService: OidcConfigService) {
//   return () =>
//     oidcConfigService.withConfig({
//       stsServer: environment.STSServer,
//       authWellknownEndpoint: environment.authWellknownEndpoint ,
//       redirectUrl: window.location.origin,
//       postLogoutRedirectUri: window.location.origin, // + '/#/logout',
//       clientId: environment.clientId,
//       scope: environment.scope,
//       responseType: environment.responseType,
//       silentRenew: true,
//       maxIdTokenIatOffsetAllowedInSeconds: environment.maxIdTokenIatOffsetAllowedInSeconds,
//       issValidationOff: true,
//       autoUserinfo: false,
//       silentRenewUrl: window.location.origin, // + '/silent-refresh.html',
//       logLevel: LogLevel.Error,
//       // customParams: {
//       //   prompt: 'login', // login, consent
//       // },
//     });
// }

//new
// export const httpLoaderFactory = (httpClient: HttpClient) => {
//   const config$:Observable<OpenIdConfiguration> = httpClient
//     .get<OpenIdConfiguration>(`https://...`)
//     .pipe(
//       map((customConfig: OpenIdConfiguration) => {
//         return {
//           /* your config mapping here */
//            authority: environment.STSServer,
//            authWellknownEndpointUrl: environment.authWellknownEndpoint ,
//      redirectUrl: window.location.origin,
     
//      clientId: environment.clientId,
//      responseType: environment.responseType,
//      scope: environment.scope,
//      postLogoutRedirectUri: window.location.origin, // + '/#/logout',
//      silentRenew: true,
//      silentRenewUrl: window.location.origin, // + '/silent-refresh.html',
//      autoUserInfo: false,
//      logLevel: LogLevel.Error,
//      issValidationOff: true,
//      maxIdTokenIatOffsetAllowedInSeconds: environment.maxIdTokenIatOffsetAllowedInSeconds
//         };
//       })
//     )
//     .toPromise();
         
      
//   return new StsConfigHttpLoader(config$);
// };

//old
// @NgModule({
//   imports: [AuthModule.forRoot()],
//   providers: [
//     OidcSecurityService,
//     OidcConfigService,
//     {
//       provide: APP_INITIALIZER,
//       useFactory: loadConfig,
//       deps: [OidcConfigService],
//       multi: true,
//     },
//   ],
//   exports: [AuthModule],
// })

//new
@NgModule({
  imports: [AuthModule.forRoot({})],
  providers: [
    OidcSecurityService,
    OidcConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: loadConfig,
      deps: [OidcConfigService],
      multi: true,
    },
  ],
  exports: [AuthModule],
})
export class AuthConfigModule {}
