import { Component, OnInit } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { MenuItem } from 'primeng/api';
import { UserDetails } from 'src/app/models/UserDetails.model';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  items: MenuItem[];

  activeItem: MenuItem;
  constructor(private oidcSecurityService: OidcSecurityService) { }

  ngOnInit(){
  
    this.items = [
      {label: 'My To-Do-List', icon: 'pi pi-pencil'},
      {label: 'Cash To-Do-List', icon: 'pi pi-money-bill'}
  ];

  this.activeItem = this.items[0];
  }

}
