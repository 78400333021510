<!-- <div class="container absolute bottom-0 right-0"  >
    <div class="row py-3">
        <div class="col-12 order-1 order-md-0">
            <p class="float-left order-1 order-md-0 pt-2 pt-md-0"  style="color:white; font-weight:normal"> © 2023 Liberty Mutual | <span>All Rights Reserved</span>
                        <p class="float-right" style="color: white; font-weight: normal;"><span> Environment: {{AppEnv}} | Version: {{AppVersion}}</span></p>
        </div>
    </div>
</div> -->



            <div class="catalyst-footer absolute p-2" [ngStyle]="{backgroundColor:'#0e1531'}">
                © 2023 Liberty Mutual | All Rights Reserved | Environment: {{AppEnv}} | Version: {{AppVersion}}
            </div>
        