import { Routes } from "@angular/router";
import { AutoLoginComponent } from "./components/auto-login/auto-login.component";
import { HomeComponent } from "./components/home/home.component";
import { SearchCededClaimComponent } from "./components/search-adjustment/search-ceded-claim/search-ceded-claim.component";
import { SearchCededPremiumComponent } from "./components/search-adjustment/search-ceded-premium/search-ceded-premium.component";
import { SearchGrossCLaimComponent } from "./components/search-adjustment/search-gross-claim/search-gross-claim.component";
import { SearchGrossPremiumComponent } from "./components/search-adjustment/search-gross-premium/search-gross-premium.component";
import { SearchMgaCashComponent } from "./components/search-adjustment/search-mga-cash/search-mga-cash.component";
import { PageNotFoundComponent } from "./components/page-not-found/page-not-found.component";
import { AuthorizationGuard } from "./services/authorization.guard";
import { DeactivateguardService } from "./services/deactivateguard.service";
import { AboutComponent } from "./components/about/about.component";
import { ContactComponent } from "./components/contact/contact.component";

export const routes: Routes=[
   
    {
        path:'',
        component:HomeComponent,
    },
    {
        path:'about',
        component:AboutComponent,
        canActivate: [AuthorizationGuard],
    },
    {
        path:'contact',
        component:ContactComponent,
        canActivate: [AuthorizationGuard],
    },
    {
        path:'autologin',
        component:AutoLoginComponent,
        canDeactivate: [DeactivateguardService],
    },
    {
        path:'search-adjustment',
        canActivate: [AuthorizationGuard],
        children:[
            {
                path:'search-gross-premium',
                component:SearchGrossPremiumComponent,
                canActivate: [AuthorizationGuard]
            },
            {
                path:'search-gross-claim',
                component:SearchGrossCLaimComponent,
                canActivate: [AuthorizationGuard]
            },
            {
                path:'search-ceded-premium',
                component:SearchCededPremiumComponent,
                canActivate: [AuthorizationGuard]
            },
            {
                path:'search-ceded-claim',
                component:SearchCededClaimComponent,
                canActivate: [AuthorizationGuard]
            },
            {
                path:'search-mga-cash',
                component:SearchMgaCashComponent,
                canActivate: [AuthorizationGuard]
            },
            
        ]
    },
    
    { path: '**', component:  PageNotFoundComponent}
];