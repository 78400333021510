<p-tabView>
            <p-tabPanel header="My To-Do-List">
                <p-table [resizableColumns]="true" columnResizeMode="expand" class=" p-datatable-gridlines" [tableStyle]="{'min-width': '50rem'}">
                    <ng-template pTemplate="caption">
                        My To-Do-List
                    </ng-template>
                    <ng-template pTemplate="header">
                        <tr>
                            <th pSortableColumn="seqBatch">Sequence Batch <p-sortIcon field="seqBatch"></p-sortIcon></th>
                            <th pSortableColumn="adjBatch">Adjustment Batch ID <p-sortIcon field="adjBatch"></p-sortIcon></th>
                            <th pSortableColumn="parentID">Parent ID <p-sortIcon field="parentID"></p-sortIcon></th>
                            <th pSortableColumn="typeLevel">Type Level <p-sortIcon field="typeLevel"></p-sortIcon></th>
                            <th pSortableColumn="ref">Reference <p-sortIcon field="ref"></p-sortIcon></th>
                            <th pSortableColumn="file">File Name <p-sortIcon field="file"></p-sortIcon></th>
                            <th pSortableColumn="transact">Transaction Date <p-sortIcon field="transact"></p-sortIcon></th>
                            <th pSortableColumn="adjStatus">Adjustment Status <p-sortIcon field="adjStatus"></p-sortIcon></th>
                            <th pSortableColumn="nextStep">Next Step <p-sortIcon field="nextStep"></p-sortIcon></th>
                            <th pSortableColumn="detail">Detail View <p-sortIcon field="detail"></p-sortIcon></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body">
                        <tr>
                            <td>1</td>
                            <td>1</td>
                            <td>1</td>
                            <td>1</td>
                            <td>1</td>
                            <td>1</td>
                            <td>1</td>
                            <td>1</td>
                            <td>1</td>
                            <td>1</td>
                        </tr>
                    </ng-template>
                </p-table>
              
            </p-tabPanel>
            <p-tabPanel header="Cash To-Do-List">
                <p-table sortMode="multiple" >
                    <ng-template pTemplate="caption">
                        Cash To-Do-List
                    </ng-template>
                    <ng-template pTemplate="header">
                        <tr>
                            <th>Adjustment</th>
                            <th>Reference</th>
                            <th>Adjustment Status</th>
                            <th>Next Step</th>
                            <th>Detail View</th>
                            <th>Status</th>
                            <th>Edit</th>
                            <th>Log</th>
                            <th>Delete</th>
                            <th>Msg</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body">
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </ng-template>
                </p-table>
            </p-tabPanel>
        </p-tabView>

