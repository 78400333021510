import { Injectable } from '@angular/core';
 
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError} from 'rxjs/operators';
 
@Injectable()
export class HomeControllerService {
 
  // baseURL: string = "https://localhost:50678/api/products";
  baseURL: string = "http://localhost:3000/greeting";
 
  constructor(private http: HttpClient) {
  }
 
  getRepos(): Observable<any> {
    return this.http.get(this.baseURL);
  }
 
}