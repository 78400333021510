import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { NgxSpinnerService } from 'ngx-spinner';
// import { getIcon } from './helpers/primeng';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  userDisplayName: string;
  isAuthenticated: boolean;
  NNumber: string;
  AtHash: string;
  logout: string;
  userData: any;
  header = false;

  constructor(
    public oidcSecurityService: OidcSecurityService,
    private router:Router,
    public spinner:NgxSpinnerService
    ){}

  ngOnInit(){
    // alert("1");
    console.log("app component")
    this.oidcSecurityService.checkAuth().subscribe((auth) => {
      // alert("2");
      console.log("auth", auth)
      this.isAuthenticated = auth;
      // alert("3 :"+auth);
      if (!this.isAuthenticated) {
        // alert("Not authenticated");
       this.router.navigate(['autologin']);
      //  alert("6")
      } else {
        // alert("authenticated")
        this.spinner.show();
        this.oidcSecurityService.userData$.subscribe((userData) => {
          this.NNumber = userData.employeeid;
          this.userDisplayName = userData.name.toString();
          this.AtHash = userData.at_hash;
          // if(localStorage.getItem('gridColSettings')) {
          //   // alert('checking at app comp')
          //   localStorage.removeItem('gridColSettings');
          // }
          sessionStorage.setItem('At_Hash', userData.at_hash);          
          // this.service.getAuthUser({NNumber: this.NNumber, At_hash:  this.AtHash}).subscribe((res) => {
          //   if (res.body.results.length === 0) {
          //      this.service.checkUserAuth({NNumber: this.NNumber, At_hash:  this.AtHash}, this.logout = 'false').subscribe((data) => {
          //        if (data.body.results.length > 0) {
          //          console.log(res, 'user is not Available');
          //        } else {
          //         console.log('Already in audit table', data.body.results[0].sSessionId);
          //        }
          //     });
          //   } else {
          //     console.log('Already in audit table', res.body.results[0].sSessionId);
          //   }
          // });
          sessionStorage.setItem('UserNumber', this.NNumber);
          sessionStorage.setItem('userDisplayName', this.userDisplayName);   
          console.log(this.NNumber+':'+this.userDisplayName);       
            //  this.service.getAuthenticatedUser(this.NNumber).subscribe((response) => {
            //    if (response.statusCode === 200) {
            //      this.userData = response.body;
                 sessionStorage.setItem('Authentication', JSON.stringify(this.userData));
            //      this.header = true;                 
                 this.spinner.hide();             
                //this.router.navigate(['home']);
            //    } else {
            //      sessionStorage.removeItem('Authentication');
            //      this.router.navigateByUrl('forbidden');
            //      this.spinner.hide();
            //    }
            //  }, error => {
            //    console.error(error);
            //  });
      });
    }
    });
  }

}
