import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  AppVersion: string;
  AppEnv: string;
  constructor() { }

  ngOnInit()  {
    this.AppVersion = environment.AppVersion;
    this.AppEnv = environment.AppEnv;
  }

}
