import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bulk-ceded-claim',
  templateUrl: './bulk-ceded-claim.component.html',
  styleUrls: ['./bulk-ceded-claim.component.scss']
})
export class BulkCededClaimComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
