import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { UserDetails } from 'src/app/models/UserDetails.model';
import { HomeControllerService } from 'src/app/services/homeController.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  User: UserDetails = new UserDetails();
  @Output() isMenuOpen = new EventEmitter<boolean>();
  @Input() isAuthenticated: boolean;
  @Input() header: boolean;
  menuItems: any;
  menuOpen = false;
  accessCode: boolean;
  NNumber: string;
  AtHash: string;
  logoutData: string;

  items: any[];
  fcrItems : any[];
  selectedMenu: string = "Home";
  // policyopenConfirmDialog: boolean = false;

  constructor(
    // private navigationService: NavigationService,
    private oidcSecurityService: OidcSecurityService,
    // public service: APIService,
    public router: Router,
    private https:HttpClient,
    private home:HomeControllerService) {
  }

  ngOnInit() {
    this.User.NNumber = sessionStorage.getItem('UserNumber');
    // this.initialiseMenu();

    // checking user authenticiation //
    const accessCodeData = sessionStorage.getItem('Authentication');
    if (accessCodeData) {
      this.accessCode = true;
    } else {
      this.accessCode = false;
    }
    //initialising home menubar
        // this.items = [
        //     {
        //         label: 'Home',
        //         icon:'pi pi-home',
        //         routerLink:'home'
        //     },
        //     {
        //         label: 'About',
        //         icon: 'pi pi-building',
        //         routerLink:'about'
        //     },
        //     {
        //       label: 'Contact',
        //       icon: 'pi pi-inbox',
        //       routerLink:'contact'
        //   }
        // ];

    //initialising FCR menubar
    this.fcrItems = [
      {
          label: 'Search Adjustment',
          icon: 'pi pi-search',
          
          items: [{
                  label: 'Gross Premium',    
                  routerLink :'./search-adjustment/search-gross-premium'
              },
              {
                label: 'Gross Claim',
                routerLink :'./search-adjustment/search-gross-claim'
            },
              {label: 'Ceded Premium',
              routerLink :'./search-adjustment/search-ceded-premium'},
              {label: 'Ceded Claim',
              routerLink :'./search-adjustment/search-ceded-claim'},
              {label: 'MGA Cash',
              routerLink :'./search-adjustment/search-mga-cash'},
          ]
      },
      {
        label: 'Create Adjustment',
        icon: 'pi pi-file-edit',
          items: [{
                  label: 'Journal Entry', 
              },
              {label: 'Gross Premium',},
              {label: 'Gross Claim'},
              {label: 'Cash Entry'},
              {label: 'Ceded Premium'},
              {label: 'Ceded Claim'}
              
          ]
      },
      {
        label: 'User Administration',
        icon: 'pi pi-user',
          items: [{
                  label: 'User Management', 
                  
              },
              {label: 'Role Management'},
              {label: 'Map Roles and Rights'},
              {label: 'User Mapping'},
              {label: 'Role Delegation'},
          ]
      },
      {
        label: 'Reference Tables',
        icon: 'pi pi-table',
          items: [{
                  label: 'Accounting Period', 
                  
              },
              {label: 'Segment One Mapping'},
              {label: 'CSV Creation'}
          ]
      },
      {
        label: 'View GRD Codes',
        icon: 'pi pi-qrcode',
          items: [{
                  label: 'View GRD', 
                  
              },
          ]
      },
      {
        label: 'Reports',
        icon: 'pi pi-chart-line',
          items: [{
                  label: 'Cash Aging Report', 
                 
              },
          ]
      },
  ];
    
}

navHome($event){
  //this.router.navigateByUrl('/home');
}

navAbout($event){
  this.router.navigateByUrl('/about');
}

navContact($event){
  console.log(1);
  this.home.getRepos()
  .subscribe(
    (response) => {  
      console.log(response);                   //next() callback
      console.log('response received');
      // alert(response[0].code+" : "+ response[0].type+" : "+response[0].name+" : "+response[0].description);
      alert(response.greeting);
      
    },
    (error) => {                              //error() callback
      console.error('Request failed with error')
  
    },
    () => {                                   //complete() callback
      console.error('Request completed')      
      
    })
  console.log(2);
  // window.location.href = '/https://forge.lmig.com/issues/servicedesk/customer/portal/1290/create/13823';
}




  // toggleMenu() {
  //   this.menuOpen = !this.menuOpen;
  // }

  // initialiseMenu() {

  //   this.searchAdjustment.push(
  //     {
  //       label: 'Gross Premium', icon: 'pi pi-fw pi-file', command: () => {
  //         // this.createSession();
  //       }
  //     },
  //     {
  //       label: 'Gross Claim', icon: 'pi pi-fw pi-file', command: () => {
  //         // this.createSession();
  //       }
  //     },
  //     {
  //       label: 'Ceded Premium', icon: 'pi pi-fw pi-file', command: () => {
  //         // this.createSession();
  //       }
  //     },
  //     {
  //       label: 'Ceded Claim', icon: 'pi pi-fw pi-file', command: () => {
  //         // this.createSession();
  //       }
  //     },
  //     {
  //       label: 'MGA Cash', icon: 'pi pi-fw pi-file', command: () => {
  //         // this.createSession();
  //       }
  //     },
  //   );

  //   }
  }
