export class UserDetails {

    NNumber: string;
    SecurityUserKey: number;
    UserName: string;
    Domain: string;
    CanAccess: number;
    FirstName: string;
    LastName: string;
    RoleType: number;
    IsAdmin: number;
    appEnvname: string;
}
